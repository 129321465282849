import React, { useContext } from "react"
import LayoutContext from "gatsby-theme-photography-portfolio/src/context/LayoutContext"//"../context/LayoutContext"

import { graphql } from "gatsby"

import { Main, SidebarButtonWrapper,FooterHolder } from "../utils/styles"//"../utils/styles"
import { CloseIcon, HamburgerIcon } from "gatsby-theme-photography-portfolio/src/components/ui/icons"//"../components/ui/icons"
import Sidebar from "../components/SidebarCms"
import Grid from "../components/GridCms"
import Seo from "gatsby-theme-photography-portfolio/src/components/seo"//"../components/seo"
import Layout from "../layouts"
import MiniHeader from "../components/MiniHeader"

const ArchivePage= ({ data }) => {
 
  let imagesPreview = [];
  data.works.edges = data.works.edges.filter(el => el.node.archived)
  console.log("datadata!", data.works.edges);
  data.works.edges.forEach(el => {
    imagesPreview.push({
      preview:el.node.preview,
      archived:el.node.archived,
      title:el.node.title
    });
  });

  const images = imagesPreview;
  const categories = data.works.edges;
  //const categories = data.works.edges
  const { sidebarVisible, toggleSidebar } = useContext(LayoutContext)

  return (
    <Layout>
      <Seo title="Home" />


      <SidebarButtonWrapper>
        {sidebarVisible ? (
          <CloseIcon onClick={toggleSidebar} />
        ) : (
            <HamburgerIcon onClick={toggleSidebar} />
          )}
      </SidebarButtonWrapper>
      <Sidebar categories={categories} pageArcvhive={true} />


      <Main sidebarVisible={sidebarVisible} onClick={sidebarVisible?toggleSidebar:null}>
        <MiniHeader  sidebarVisible={sidebarVisible}>
        </MiniHeader>
        <Grid images={images} pageArcvhive="true" prefix="Main" />
        <FooterHolder
          style={{
            marginTop: `var(--space-5)`,
            fontSize: `var(--font-sm)`,
          }}
        >
          <div className="copy-footer">© Copyright <span>NADIA SHIRA COHEN 2023</span> </div>
          <div className="gerenza">Built by <span>Emiliano Pallini</span></div>
         
        </FooterHolder>
      </Main>
      
    </Layout>
  )
}

export default ArchivePage

export const query = graphql`
  query {
    
    works:allContentfulWorks(sort: {fields: order}) {
      edges {
        node {
          title
          archived
          id
          preview {
            gatsbyImageData
            title
            id
          }
        }
      }
    }
  }
`
